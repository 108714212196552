@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  body {
    font-family: "Inter", sans-serif;
  }
 
}

@layer components {
  input[type="checkbox"]:checked ~ label span svg {
    @apply inline-flex;
  }

  .sticky .header-logo {
    @apply py-5 lg:py-2;
  }

  .sticky .menu-scroll.active {
    @apply opacity-70;
  }

  input#togglePlan:checked ~ .dot {
    @apply translate-x-full;
  }

  input#checkboxLabel:checked ~ .box span {
    @apply opacity-100;
  }
}
